<template>
  <v-container fluid class="down-top-padding">
    <v-card class="white pa-3">
      <h1 class="text-center subtitle-4 black--text">الاشعارات</h1>
      <h5 class="text-center subtitle-4 black--text mt-1">
        العدد الكلي {{ table.totalData }}
      </h5>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn
              tile
              color="info"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              @click="dialogAdd.open = true"
            >
              اضافة <v-icon right> fa-plus </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field
              v-model="searching"
              label="البحث"
              outlined
              dense
              @keyup.enter="search()"
            ></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-plus',
              nextIcon: 'mdi-minus',
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{
                (tableOptions.page - 1) * tableOptions.itemsPerPage +
                props.index +
                1
              }}
            </template>
            <template v-slot:item.image="{ item }">
              <show-img
                :content_url="content_url"
                :img="item.image"
                :width="80"
                :height="100"
              ></show-img>
            </template>
            <template v-slot:item.link="{ item }">
              <a v-if="item.link" :href="item.link" target="_blank">عرض</a>
            </template>
            <template v-slot:item.receivers="{ item }">
              <ul>
                <li v-for="(receiver, index) in item.receivers" :key="index">
                  {{ receiver.name }} - ( {{ receiver.type }} )
                </li>
              </ul>
            </template>
            <template v-slot:item.sender="{ item }">
              <span>
                {{ item.sender.name }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="$store.state.results.type === 'admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteItem(item)"
                  >
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- add dialog -->
    <v-dialog v-model="dialogAdd.open" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogAdd.isFormValid">
              <v-row>
                <!-- title -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.title"
                    :rules="Rules.required"
                    dense
                    label="العنوان"
                    outlined
                  />
                </v-col>
                <!-- body -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.body"
                    dense
                    label="المحتوى"
                    outlined
                  />
                </v-col>
                <!-- upload img -->
                <v-col cols="12" md="6">
                  <image-upload-base64-vue
                    imageName="image"
                    btnText="الصورة"
                    :isDeletable="true"
                    v-on:imageUploaded="addData.image = $event"
                    :setToNull="false"
                  ></image-upload-base64-vue>
                </v-col>
                <!-- link -->
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="addData.link"
                    dense
                    label="الرابط"
                    outlined
                  />
                </v-col>
                <!-- type -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="addData.type"
                    :items="type_select"
                    dense
                    label="نوع الاشعار"
                    outlined
                  ></v-select>
                </v-col>
                <!-- receiver_type -->
                <v-col cols="12" md="6">
                  <v-select
                    v-model="addData.receiver_type"
                    item-text="name"
                    item-value="value"
                    :items="receiver_type_select"
                    dense
                    label="نوع مستلم الاشعار"
                    outlined
                  ></v-select>
                </v-col>
                <!-- receiver_type -->
                <v-col
                  cols="12"
                  md="6"
                  v-if="addData.receiver_type === 'specific'"
                >
                  <v-autocomplete
                    v-model="addData.receiver_id"
                    :items="all_accounts.data"
                    :item-text="(item) => `${item.name} ( ${item.type} )`"
                    item-value="_id"
                    :rules="Rules.required"
                    dense
                    label="المستلم"
                    outlined
                    clearable
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAdd.open = false">
            الغاء
          </v-btn>
          <v-btn
            color="secondary white--text"
            :loading="dialogAdd.loading"
            :disabled="!dialogAdd.isFormValid"
            @click="submitAdd"
          >
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add discount dialog -->
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete.open" max-width="500px">
      <v-card style="border-radius: 0">
        <v-card-title class="headline justify-center">
          هل انت متأكد من حذف هذا الحساب ؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete.open = false">
            الغاء
          </v-btn>
          <v-btn
            color="primary white--text"
            :loading="dialogDelete.loading"
            @click="deleteItemConfirm"
          >
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dialog -->
    <!--- respondMessageVue -->
    <respondMessageVue :dialogData="dialogData"></respondMessageVue>
  </v-container>
</template>

<script>
// ES modules
import notificationsApi from "@/api/notifications";
import respondMessageVue from "@/components/respondMessage.vue";
import { encrypt, decrypt } from "@/constants/cryptUrl";
import imageUploadBase64Vue from "@/components/imageUpload";
import customersApi from "@/api/customers";
import showImg from "@/components/showImg.vue";
export default {
  name: "Notifications",

  components: {
    respondMessageVue,
    imageUploadBase64Vue,
    showImg,
  },

  data: () => ({
    content_url: null,

    birthday_menu: false,

    birthday_menu_edit: false,

    rate: 0,

    errors: {},

    receiver_type_select: [
      {
        name: "الكل",
        value: "all",
      },
      {
        name: "مخصص",
        value: "specific",
      },
      {
        name: "طلاب",
        value: "student",
      },
      {
        name: "سواق",
        value: "driver",
      },
      {
        name: "اساتذة",
        value: "teacher",
      },
    ],

    type_select: ["اقساط", "تبليغ"],

    addData: {
      title: null,
      body: null,
      receiver_id: [],
      image: null,
      link: null,
      receiver_type: "all",
      type: "تبليغ",
    },

    Rules: {
      required: [(value) => !!value || "الحقل مطلوب"],
    },

    searching: null,

    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortBy: [],
      sortDesc: [],
      search: null,
      firstTime: true,
    },

    table: {
      search: null,
      totalData: 0,
      data: [],
      loading: true,

      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "_id",
        },
        {
          text: "العنوان",
          sortable: false,
          value: "title",
        },
        {
          text: "المحتوى",
          sortable: false,
          value: "body",
        },
        {
          text: "الصورة",
          sortable: false,
          value: "image",
        },
        {
          text: "الرابط",
          sortable: false,
          value: "link",
        },
        {
          text: "نوع الاشعار",
          sortable: false,
          value: "type",
        },
        {
          text: "نوع المستلم",
          sortable: false,
          value: "receiver_type",
        },
        {
          text: "المستلم",
          sortable: false,
          value: "receivers",
        },
        {
          text: "المرسل",
          sortable: false,
          value: "sender",
        },
        { text: "العمليات", value: "actions", sortable: false },
      ],
    },

    dialogData: {
      open: false,
      color: "info",
      bodyText: "test",
    },

    dialogDelete: {
      open: false,
      loading: false,
    },

    dialogAdd: {
      open: false,
      loading: false,
      isFormValid: false,
    },

    all_accounts: {
      data: [],
      loading: false,
    },
  }),

  watch: {
    tableOptions: {
      // immediate: false,

      handler() {
        if (!this.tableOptions.firstTime) {
          this.$router.push(
            {
              query: {
                filter: encrypt({
                  page: this.tableOptions.page,
                  limit: this.tableOptions.itemsPerPage,
                  search: this.table.search,
                }),
              },
            },
            () => {}
          );
        }

        this.tableOptions.firstTime = false;

        // this.getDataAxios();
      },
      // deep: true,
    },

    "$route.query.filter": {
      handler() {
        if (!this.$route.query.filter) {
          this.tableOptions.page = 1;
          this.tableOptions.itemsPerPage = 10;
          this.table.search = null;
          this.searching = null;
          return;
        }
        const filterData = decrypt(this.$route.query.filter);

        let page = filterData.page;
        let limit = filterData.limit;
        let search = filterData.search;

        this.tableOptions.page = +page;
        this.tableOptions.itemsPerPage = +limit;
        this.table.search = search;
        this.searching = search;

        this.getDataAxios();
      },
    },

    "table.search": {
      handler() {
        this.tableOptions.page = 1;
        this.tableOptions.itemsPerPage = 10;
        this.$router.push(
          {
            query: {
              filter: encrypt({
                page: this.tableOptions.page,
                limit: this.tableOptions.itemsPerPage,
                search: this.table.search,
              }),
            },
          },
          () => {}
        );
      },
    },
  },

  created() {
    this.getDataAxios();
    this.getAllAccounts();
  },
  methods: {
    async getDataAxios() {
      this.table.loading = true;
      let search = this.table.search;
      let page = this.tableOptions.page;
      let limit = this.tableOptions.itemsPerPage;

      if (!search) {
        search = "";
      }

      const response = await notificationsApi.get({
        page,
        limit,
        search,
      });

      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.table.loading = false;
        this.showDialogFunction(response.data.results, "#FF5252");
      } else {
        this.table.loading = false;
        this.table.data = response.data.results.data;
        this.table.totalData = response.data.results.count;
        this.content_url = response.data.content_url;
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
      this.dialogDelete.open = true;
    },

    async deleteItemConfirm() {
      this.dialogDelete.loading = true;

      const response = await notificationsApi.remove(this.deletedItem._id);

      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.dialogDelete.loading = false;
        this.dialogDelete.open = false;
        this.showDialogFunction(response.data.message, "#FF5252");
      } else {
        this.dialogDelete.loading = false;
        this.dialogDelete.open = false;
        this.getDataAxios();
        this.showDialogFunction(response.data.message, "info");
      }
    },

    async submitAdd() {
      this.dialogAdd.loading = true;

      const response = await notificationsApi.add({
        title: this.addData.title,
        body: this.addData.body,
        image: this.addData.image,
        link: this.addData.link,
        type: this.addData.type,
        receiver_type: this.addData.receiver_type,
        receiver_id: this.addData.receiver_id,
      });

      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.dialogAdd.open = false;
        this.dialogAdd.loading = false;
        this.showDialogFunction(response.data.message, "#FF5252");
      } else {
        this.dialogAdd.loading = false;
        this.dialogAdd.open = false;

        this.addData.title = null;
        this.addData.body = null;
        this.addData.image = null;
        this.addData.link = null;
        this.addData.type = "تبليغ";
        this.addData.receiver_type = "all";
        this.addData.receiver_id = [];

        this.showDialogFunction(response.data.message, "info");
        this.getDataAxios();
      }
    },

    async getAllAccounts() {
      this.all_accounts.loading = true;

      const response = await customersApi.getAllAccounts();
      if (response.status === 401) {
        this.$store.dispatch("submitLogout");
      } else if (response.status === 500) {
        this.all_accounts.loading = false;
        this.showDialogFunction(response.data.message, "#FF5252");
      } else {
        this.all_accounts.loading = false;
        this.all_accounts.data = response.data.results;
      }
    },

    search() {
      this.table.search = this.searching;
    },

    showDialogFunction(bodyText, color) {
      this.dialogData.open = true;
      this.dialogData.bodyText = bodyText;
      this.dialogData.color = color;
    },
  },
};
</script>
