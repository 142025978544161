import axios from "axios";

class notificationsApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/notifications?page=${page}&limit=${limit}&search=${search}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async add({
    title,
    body,
    link,
    image,
    type,
    receiver_id,
    receiver_type,
  }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .post("/notifications", {
        title,
        body,
        link,
        image,
        type,
        receiver_id,
        receiver_type,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .delete(`/notifications/id/${id}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }
}

export default new notificationsApi();
